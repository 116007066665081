@font-face {
    font-family: "Vazirmatn-Bold";
    src: local("Vazirmatn-Bold"),
        url("../fonts/Vazirmatn-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Vazirmatn";
    src: local("Vazirmatn-Regular"),
        url("../fonts/Vazirmatn-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "NokaTrial-Bold";
    src: local("NokaTrial-Bold"),
        url("../fonts/NokaTrial-Bold-BF63bccdec13b50.otf") format("truetype");
}

@font-face {
    font-family: "NokaTrial-SemiBold";
    src: local("NokaTrial-SemiBold"),
        url("../fonts/NokaTrial-Semibold-BF63bccdec16a7a.otf") format("truetype");
}


@font-face {
    font-family: "ReadexPro";
    src: local("ReadexPro-Bold"),
        url("../fonts/ReadexPro-Bold.ttf") format("truetype");
}


@media (min-width: 768px) {
    .sideLeft {
        clip-path: polygon(0 0, 100% 0, calc(100% - 5px) 100%, 0 100%)
    }

    .sideRight {
        clip-path: polygon(calc(10vw + 5px) 0, 100% 0, 100% 100%, 0 100%)
    }

    .sideLeft2 {
        clip-path: polygon(0 0, 90% 0, 100% 100%, 5px 100%);
    }
    
    .sideRight2 {
        clip-path: polygon(0 0, calc(90vw - 5px) 0, calc(90vw - 5px) 100%, 0 100%);
    }

    .sideLeft3 {
        clip-path: polygon(0 0, 100% 0, 90% 100%, 5px 100%);
    }
    
    .sideRight3 {
        clip-path: polygon(0 0, calc(90vw - 5px) 0, calc(90vw - 5px) 100%, 0 100%);
    }
}


.miniCarIcon {
    transition: all .2s ease-in-out
}

.miniCarIcon:hover {
    transform: scale(1.1);
}

.video {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-width: 560px;
}

.colorEasycar{
    color: #338e40;
}

.colorWhite{
    color: #FFF;
}